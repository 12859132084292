<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">新增预约</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="选择预约项目">
							<a-select style="width: 260px" placeholder="选择预约项目" v-model="form.projectId">
								<a-select-option :value="0">请选择</a-select-option>
								<a-select-option v-for="(item,index) in projects" :key="index" :value="item.project_id">{{item.name}}</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item v-if="isSku == 1" required label="选择预约规格">
							<a-select style="width: 260px" placeholder="选择预约项目" v-model="form.skuId">
								<a-select-option :value="0">请选择</a-select-option>
								<a-select-option v-for="(item,index) in getSkus" :key="index" :value="item.sku_id">{{item.name}}</a-select-option>
							</a-select>
						</a-form-model-item>
						
						<a-form-model-item v-if="isMult == 1" required label="选择预约门店">
							<a-select style="width: 260px" placeholder="选择预约门店" v-model="form.mendianId">
								<a-select-option :value="0">请选择</a-select-option>
								<a-select-option v-for="(item,index) in mendian" :key="index" :value="item.mendian_id">{{item.name}}</a-select-option>
							</a-select>
						</a-form-model-item>
						
						<a-form-model-item required label="预约联系人">
							<a-input v-model="form.name"  style="width: 200px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="预约手机号">
							<a-input v-model="form.mobile"    style="width: 200px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="车牌">
							<a-input v-model="form.car_num"   style="width: 200px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="车型">
							<a-input v-model="form.car_name"    style="width: 200px;"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="预约日期">
							<a-date-picker  valueFormat="YYYY-MM-DD" v-model="form.day"/>
							
						</a-form-model-item>
						<a-form-model-item required label="服务时间">
							<a-time-picker v-model="form.yuyue_time"  valueFormat="HH:mm" format="HH:mm" />
						</a-form-model-item>
						
					
					
						
						
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
					
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../components/upload/upload.vue';
	export default{
		components:{
			uploadImg,
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 20 },
				projects:[],
				isMult:0,
				skus:[],
				mendian:[],
				form: {
					projectId:0,
					skuId:0,
					mendianId:0,
					mobile:'',
					name:'',
					car_num:'',
					car_name:'',
					day:'',
					yuyue_time:'08:00'
				}
			}
		},
		created() {
			
			this.loaddata();
		},
		computed:{
			isSku(){
				if(this.form.projectId == 0) return 0;
				for(var a in this.projects){
					if(this.projects[a].project_id == this.form.projectId){
						return this.projects[a].is_sku;
					}
				}
				return 0;
			},
			getSkus(){
				let arr = new Array;
				for(var a in this.skus){
					if(this.skus[a].project_id == this.form.projectId){
						arr.push(this.skus[a]);
					}
				}
				return arr;
			}
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getYuyueOrderSetting').then(res=>{
					this.projects = res.projects;
					this.isMult = res.isMult;
					this.skus = res.skus;
					this.mendian = res.mendian;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/addYuyueOrder',this.form).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$router.go(-1);
					});
				}).catch(res=>{
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>